.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background: #000;
    color: #fff;
    padding: 5px;
}

.footer p {
    margin: 5px 0;
    font-size: 40px;
    font-weight: 600;
}


.social-icons {
    margin: 10px 0;
    width: 30%;
    display: flex;
    justify-content: space-evenly;
}

.social-icons i {
    color: #fff;
    font-size: 50px;
}

.social-icons i:hover {
    color: rgb(255, 0, 0);

}

@media only screen and (max-width:550px) {
    .social-icons {
        width: 100%;
        font-size: 30px;
    }

    .footer p {
        font-size: 30px;
    }
}