.top-places {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../images/home/toplplaace.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
}

.home__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    text-align: center;
}

.topplaces__heading {
    font-size: 100px;
    color: #fff;
}


.topplaces__description {
    font-size: 80px;
    background: linear-gradient(90deg, #ffb8b8, #0040ff, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-family: 'Space Grotesk', sans-serif;
}

.explore-btn:hover {
    background: #000000;
    color: #ebefff;
    border: 2px solid #ffffff;
    border-radius: 8px;
}


.top-places-container {
    background-color: #e3e3ec;
    padding: 10px 50px;
    text-align: justify;
    white-space: normal;
}

.top-places-container h2 {
    color: rgb(0, 62, 62);
    padding-top: 10px;

}

.top-places-container h2 span {
    background: linear-gradient(90deg, #ff0000, #057105, #0b0b69);
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 800;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.top-places-grid-wrapper {
    padding: 15px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 3rem 0;

}

.top-places-box {
    color: #fff;
    height: 250px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.5rem;
    box-shadow: 1px 1px 5px 2px #000000;
    transition: all 0.5s;
    border-radius: 3px;
    /* border: 1px solid #ff0011; */
}

.top-places-box:hover {
    transform: scale(1.08);
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 2px #100643;
    background-blend-mode: soft-light;
    color: #e72626;
    font-weight: 700;
}

.top-places-box p {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 1.8rem;
    font-family: 'Secular One', sans-serif;

}

.top-places-box span {
    border: 2px solid #fff;
    width: 100px;
    margin-bottom: 20px;
    box-shadow: 2px 1px 10px 1px #ff0011;
}

.top-places-box a {
    font-weight: 600;
    text-decoration: none;
    color: #ffe600;
    transition: all 0.5s;
    font-family: 'Space Grotesk', sans-serif;
}

.top-places-box a:hover {
    text-decoration: underline;
}


@media only screen and (max-width:1200px) {
    .top-places-container {
        background-color: #F8F8FF;
        padding: 10px 0;
    }

    .top-places-grid-wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem 0;
    }

}

@media screen and (max-width:700px) {

    .topplaces__heading {
        font-size: 50px;
    }

    .topplaces__description {
        font-size: 40px;
    }

    .top-places-container {
        background-color: #F8F8FF;
        padding: 10px;
    }

    .top-places-grid-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }

    .top-places-box {
        color: #fff;
        height: 300px;
        width: 350px;
    }

}

@media only screen and (max-width:400px) {
    .top-places-container {
        background-color: #F8F8FF;
        padding: 8px 0px;
    }

    .top-places-box {
        color: #fff;
        height: 250px;
        width: 285px;
    }

    .top-places-box:hover {
        transform: scale(1.05);
    }

}